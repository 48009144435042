/* eslint-disable import/prefer-default-export */
import React from 'react';
import {toVND} from './helper';

export const generatePrice = (oldPrice, newPrice) => {
  if (oldPrice && oldPrice > newPrice)
    return (
      <>
        <span className='item-old-price'>{toVND(oldPrice)}</span>
        <span className='item-between-price'>|</span>
        <span className='item-new-price'>{toVND(newPrice)}</span>
      </>
    );
  return <span className='item-new-price'>{toVND(newPrice)}</span>;
};
