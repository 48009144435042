import React, {useState, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import {useCookies} from 'react-cookie';
import {Link} from 'gatsby';
import {Badge, Popover, Empty, Row} from 'antd';

import {getLocalRentalDate} from '~utils/helper';
import {get} from '~utils/api';
import {getCarts, getCartFromCookies} from '~actions/cart';

import Cart from '../icons/cart';
import CartItem from '../cart-item';

const CartPopover = ({carts, user, isGuest, getListCartFromCookies, getListCart}) => {
  const [visibleCartMenu, setVisibleCartMenu] = useState(false);
  const [cookies] = useCookies(['cart-items']);

  useEffect(() => {
    if (user && user.username && !isGuest) {
      const getUserCarts = async () => {
        await getListCart();
      };

      getUserCarts();
    }
  }, [user]);

  useEffect(() => {
    if (isGuest) {
      const getCartsInCookie = async () => {
        try {
          const cartItems = cookies['cart-items'] || [];
          if (cartItems.length !== 0) {
            const productIds = cartItems.reduce((result, x) => {
              if (!result.includes(x.productId)) result.push(x.productId);
              return result;
            }, []);
            const products = await Promise.all(
              productIds.map(async id => {
                const res = await get(`/v1/product/product-detail/${id}`);
                return res;
              })
            );

            const cookiesCarts = cartItems.map(x => {
              const item = products.find(y => y.id === x.productId);
              if (item) {
                const selectedVariant = item.variants.find(
                  vari =>
                    vari.attributesCombination?.length === x.attributes?.length &&
                    vari.attributesCombination.every(
                      com =>
                        x.attributes.findIndex(
                          att => att.attributeId === com.attributeId && att.attributeValueId === com.attributeValueId
                        ) > -1
                    )
                );

                return {
                  ...x,
                  ...item,
                  productName: item.name,
                  productImage: item.primaryImageUrl,
                  available: true,
                  salePrice: item.salePrice + (selectedVariant?.priceDisparity ?? 0),
                  rentalPrice: item.rentalPrice + (selectedVariant?.rentalPriceDisparity ?? 0),
                  variant: selectedVariant,
                  dateFrom: x.dateFrom && getLocalRentalDate(x.dateFrom, -1),
                  dateTo: x.dateTo && getLocalRentalDate(x.dateTo, 1),
                };
              }
              return {...x, available: false};
            });

            getListCartFromCookies(cookiesCarts ?? []);
            return;
          }
        } catch (e) {
          //
        }
        getListCartFromCookies([]);
      };

      getCartsInCookie();
    }
  }, [cookies, isGuest]);

  const getCartMenu = useCallback(() => {
    return (
      <div className='cart-popup'>
        {carts?.total > 0 ? (
          <>
            <div className='cart-menu'>
              {carts.items.map(item => (
                <div key={isGuest ? item.cookieId : item.id}>
                  <CartItem item={item} />
                  <div className='cart-border' />
                </div>
              ))}
            </div>
          </>
        ) : (
          <Empty description='Không có sản phẩm trong giỏ hàng!' />
        )}
        <Row justify='end' className='cart-action'>
          <Link className='cart-order' to='/checkout/cart'>
            Xem giỏ hàng
          </Link>
        </Row>
      </div>
    );
  }, [carts]);

  return (
    <Popover
      placement='bottomRight'
      content={getCartMenu()}
      trigger='click'
      visible={visibleCartMenu}
      onVisibleChange={visible => setVisibleCartMenu(visible)}
    >
      <div className='checkout-cart'>
        {carts.total > 0 ? (
          <Badge count={carts.total}>
            <Cart fill='#000000' />
          </Badge>
        ) : (
          <Cart />
        )}
        <span className='cart-label'>Giỏ Hàng</span>
      </div>
    </Popover>
  );
};

export default connect(
  state => ({
    carts: state.cart.data,
    user: state.user.user,
    isGuest: state.user.isGuest,
  }),
  dispatch => ({
    getListCartFromCookies: cart => dispatch(getCartFromCookies(cart)),
    getListCart: () => dispatch(getCarts()),
  })
)(CartPopover);
