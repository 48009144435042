import React from 'react';
import NotFound from '~containers/not-found';
import notifyWebhook from './webhook';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    notifyWebhook(`${error.toString()} - ${JSON.stringify(errorInfo)}`);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <NotFound title='500' content='Có lỗi xảy ra trên trang web' />;
    }

    return this.props.children;
  }
}
