export const address = {
  companyName: 'HỘ KINH DOANH RENTZY',
  mst: '8838500693-001',
  address: '51 Đường số 41 - phường 06 - Quận 4 - TP Hồ Chí Minh',
};

export const followUs = {
  facebook: 'http://www.facebook.com/rentzy.vn',
  instagram: 'http://rentzy.vn/',
  tiktok: 'http://rentzy.vn/',
};

export const email = 'rental@rentzy.vn';

export const hotline = '079 8969040';

export default {};
