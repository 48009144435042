import React from 'react';

const AppleStoreCircle = ({fill = '#000000', size = '24'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 512 512'
    enableBackground='0 0 512 512'
    width={size}
    height={size}
    fill={fill}
  >
    <circle style={{fill}} cx='256' cy='256' r='256' />
    <path
      style={{fill: '#1A1A1A'}}
      d='M248.989,139.771L316,206.541L161.382,402.958l114.306,108.296C407.874,501.201,512,390.76,512,256
	c0-3.303-0.083-6.587-0.207-9.86L321.541,55.888L248.989,139.771z'
    />
    <path
      style={{fill: '#ffffff'}}
      d='M191.187,419.533c-10.268-0.401-25.607-11.596-32.251-19.129
	c-17.002-19.278-30.852-40.639-40.647-64.442c-11.15-27.094-17.801-55.193-17.464-84.691c0.242-21.218,4.384-41.569,15.006-60.09
	c13.133-22.899,32.522-37.959,58.435-44.047c10.311-2.422,20.822-3.394,31.127-0.345c10.303,3.048,20.455,6.646,30.564,10.303
	c8.759,3.169,17.355,5.462,26.551,1.477c6.042-2.619,12.326-4.681,18.514-6.958c10.135-3.73,20.379-7.188,31.29-7.513
	c21.103-0.63,40.57,4.546,57.743,17.179c7.082,5.21,13.035,11.54,17.974,18.956c-0.679,0.512-1.199,0.936-1.75,1.315
	c-7.396,5.085-13.946,11.087-19.612,18.053c-15.04,18.489-20.26,39.594-16.71,62.998c2.477,16.33,9.514,30.427,20.994,42.309
	c7.016,7.262,18.796,14.858,27.862,19.26c-1.58,3.746-4.678,9.791-6.183,13.572c-6.422,16.138-15.065,31.035-25.235,45.085
	c-7.232,9.991-14.51,19.898-24.391,27.57c-9.041,7.02-19.048,10.529-30.579,9.728c-8.784-0.61-16.915-3.354-24.785-6.997
	c-17.197-7.962-34.848-9.551-52.987-3.873c-4.524,1.416-8.792,3.638-13.303,5.104C214.188,416.684,198.713,419.827,191.187,419.533z
	'
    />
    <g>
      <path
        style={{fill: '#ffffff'}}
        d='M321.541,55.888c-0.044,5.787,0.616,11.683-0.259,17.341c-3.245,20.972-13.09,38.464-29.665,51.791
		c-12.286,9.878-26.191,15.773-42.628,14.751c-0.934-6.334-0.726-12.619,0.639-18.772c4.882-22.005,16.587-39.523,35.383-52.026
		c9.701-6.453,20.346-10.858,31.986-12.558C317.554,56.333,320.57,55.888,321.541,55.888z'
      />
    </g>
  </svg>
);

export default AppleStoreCircle;
