/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable global-require */
import * as React from 'react';
import {Link} from 'gatsby';
import {Row, Col} from 'antd';
import {Footer} from 'antd/lib/layout/layout';

import {address, followUs} from '~constants/rentzy-info';
import FacebookCircle from './icons/facebook-circle';
import InstagramCircle from './icons/instagram-circle';
import AppleStoreCircle from './icons/apple-store-circle';
import GoogleStoreCircle from './icons/google-store-circle';
import PhoneDesign from './icons/phone-design';
import TiktokCircle from './icons/tiktok-circle';

const FooterComponent = () => {
  return (
    <Footer className='layout-footer'>
      <Row className='footer-content' justify='center'>
        <Col style={{paddingBottom: '50px'}} lg={24} xl={22}>
          <Row justify='space-between'>
            <Col style={{padding: '0 10px'}} xs={24} md={18} lg={18}>
              <Row justify='space-between'>
                <Col className='f-item' xs={24} md={8} lg={8}>
                  <h4>CHĂM SÓC KHÁCH HÀNG</h4>
                  <ul className='content'>
                    <li>
                      <Link to='/info/cach-thuc-thue-trang-phuc.html'>Cách thức thuê trang phục</Link>
                    </li>
                    <li>
                      <Link to='/info/cau-hoi-thuong-gap.html'>Câu hỏi thường gặp</Link>
                    </li>
                    <li>
                      <Link to='/info/chinh-sach-doi-tra.html'>Chính sách đổi trả</Link>
                    </li>
                    <li>
                      <Link to='/info/chinh-sach-thanh-ly.html'>Chính sách thanh lý</Link>
                    </li>
                    <li>
                      <Link to='/info/dieu-khoan-su-dung.html'>Điều khoản sử dụng</Link>
                    </li>
                    <li>
                      <Link to='/info/hinh-thuc-thanh-toan.html'>Hình thức thanh toán</Link>
                    </li>
                  </ul>
                </Col>
                <Col className='f-item' xs={24} md={8} lg={8}>
                  <h4>VỀ RENTZY</h4>
                  <ul className='content'>
                    <li>
                      <Link to='/about/gioi-thieu-rentzy.html'>Giới thiệu Rentzy</Link>
                    </li>
                    <li>
                      <Link to='/about/cong-dong.html'>Cộng đồng</Link>
                    </li>
                    <li>
                      <Link to='/about/quy-trinh-giat-hap.html'>Quy trình giặt hấp</Link>
                    </li>
                    <li>
                      <Link to='/about/chinh-sach-bao-mat-thong-tin.html'>Chính sách bảo mật thông tin</Link>
                    </li>
                    <li>
                      <Link to='/about/tuyen-dung.html'>Tuyển dụng</Link>
                    </li>
                    <li>
                      <Link to='/about/lien-he-voi-chung-toi.html'>Liên hệ với chúng tôi</Link>
                    </li>
                  </ul>
                </Col>
                <Col className='f-item' xs={24} md={8} lg={8}>
                  <h4>THEO DÕI CHÚNG TÔI TẠI</h4>
                  <ul className='content' style={{margin: '4px 4px 40px'}}>
                    <li>
                      <Link to={followUs.facebook} className='link-with-icon' target='_blank'>
                        <FacebookCircle />
                        <span className='title'>Facebook</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={followUs.instagram} className='link-with-icon' target='_blank'>
                        <InstagramCircle />
                        <span className='title'>Instagram</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={followUs.tiktok} className='link-with-icon' target='_blank'>
                        <TiktokCircle />
                        <span className='title'>Tiktok</span>
                      </Link>
                    </li>
                  </ul>
                  <h4 hidden>TẢI ỨNG DỤNG TẠI</h4>
                  <ul hidden className='content'>
                    <li>
                      <Link to='#' className='link-with-icon'>
                        <AppleStoreCircle />
                        <span className='title'>Apple Store</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='#' className='link-with-icon'>
                        <GoogleStoreCircle />
                        <span className='title'>Google play</span>
                      </Link>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row justify='space-between'>
                <Col hidden className='f-item' xs={24} md={8} lg={8}>
                  <h4>PHƯƠNG THỨC THANH TOÁN</h4>
                  <div className='border-component' />
                  {/* <Row justify='space-between'>
                  <Col style={{padding: '0 20px'}} span={8}>
                    <div className='visa' />
                  </Col>
                  <Col style={{padding: '0 20px'}} span={8}>
                    <div className='master-card' />
                  </Col>
                  <Col style={{padding: '0 20px'}} span={8}>
                    <div className='jcb-card' />
                  </Col>
                </Row>
                <Row justify='space-between'>
                  <Col style={{padding: '0 20px'}} span={8}>
                    <div className='tien-mat' />
                  </Col>
                  <Col style={{padding: '0 20px'}} span={8}>
                    <div className='internet-banking' />
                  </Col>
                  <Col style={{padding: '0 20px'}} span={8}>
                    <div className='internet-banking' />
                  </Col>
                </Row> */}
                </Col>
                <Col hidden className='f-item' xs={24} md={8} lg={8}>
                  <h4>ĐƠN VỊ VẬN CHUYỂN</h4>
                  <div className='border-component' />
                </Col>
                <Col className='f-item' xs={24} md={8} lg={8}>
                  <h4>ĐỊA CHỈ</h4>
                  <div className='border-component'>
                    <div className='company-name'>{address.companyName}</div>
                    <div className='mst'>
                      MST: <span>{address.mst}</span>
                    </div>
                    <div className='address'>
                      Địa chỉ: <span>{address.address}</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className='phone-design' xs={0} md={6} lg={6}>
              <PhoneDesign />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className='license'>&copy; 2021 - BẢN QUYỀN THUỘC VỀ HKD RENTZY</div>
    </Footer>
  );
};

export default FooterComponent;
