import React from 'react';

const Favorite = ({fill = '#000000', size = '24'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 20 20'
    width={size}
    height={size}
    fill={fill}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 2.91501C11.09 1.63501 12.76 0.825012 14.5 0.825012C17.58 0.825012 20 3.24501 20 6.32501C20 10.1019 16.6056 13.18 11.4627 17.8435L11.45 17.855L10 19.175L8.55 17.865L8.51052 17.8291C3.38263 13.1693 0 10.0953 0 6.32501C0 3.24501 2.42 0.825012 5.5 0.825012C7.24 0.825012 8.91 1.63501 10 2.91501ZM10 16.475L10.1 16.375C14.86 12.065 18 9.21501 18 6.32501C18 4.32501 16.5 2.82501 14.5 2.82501C12.96 2.82501 11.46 3.81501 10.94 5.18501H9.07C8.54 3.81501 7.04 2.82501 5.5 2.82501C3.5 2.82501 2 4.32501 2 6.32501C2 9.21501 5.14 12.065 9.9 16.375L10 16.475Z'
    />
  </svg>
);

export default Favorite;
