import {navigate} from 'gatsby';
import React from 'react';
import NotFoundIcon from '~components/icons/not-found';

import './styles.scss';

const NotFound = ({title = '404', content = 'Không tìm thấy trang'}) => {
  return (
    <div className='not-found-page'>
      <div className='container'>
        <div className='icon'>
          <NotFoundIcon />
        </div>
        <div className='title'>{title}</div>
        <div className='content'>{content}</div>
        <div className='btn'>
          <div className='back' onClick={() => navigate(-1)}>
            Quay lại
          </div>
          <div className='back-home' onClick={() => navigate('/home')}>
            Tiếp tục mua hàng
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
