import React from 'react';

const Cart = ({fill = '#000000', size = '24'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 22 20'
    width={size}
    height={size}
    fill={fill}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.79166 0H0V2H3.2184L5.9872 14.6776H6V15H19V14.7519L21.1932 5.09095L21.5308 4H5.6552L5.08485 1.38852L4.79166 0ZM18.9869 6H6.092L7.62081 13H17.3978L18.9869 6Z'
    />
    <path d='M9 20C10.1046 20 11 19.1046 11 18C11 16.8954 10.1046 16 9 16C7.89543 16 7 16.8954 7 18C7 19.1046 7.89543 20 9 20Z' />
    <path d='M18 18C18 19.1046 17.1046 20 16 20C14.8954 20 14 19.1046 14 18C14 16.8954 14.8954 16 16 16C17.1046 16 18 16.8954 18 18Z' />
  </svg>
);

export default Cart;
