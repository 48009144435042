import React from 'react';

const MenuIcon = ({fill = '#black', size = '24'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 18 14'
    width={size}
    height={size}
    fill={fill}
  >
    <path d='M9 0C8.44772 0 8 0.447715 8 1C8 1.55228 8.44772 2 9 2H17C17.5523 2 18 1.55228 18 1C18 0.447715 17.5523 0 17 0H9Z' />
    <path d='M4 7C4 6.44771 4.44772 6 5 6H13C13.5523 6 14 6.44771 14 7C14 7.55228 13.5523 8 13 8H5C4.44772 8 4 7.55228 4 7Z' />
    <path d='M0 13C0 12.4477 0.447715 12 1 12H9C9.55228 12 10 12.4477 10 13C10 13.5523 9.55229 14 9 14H1C0.447716 14 0 13.5523 0 13Z' />
  </svg>
);

export default MenuIcon;
