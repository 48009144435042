/* eslint-disable no-undef */
import React, {useEffect} from 'react';

export default function GoogleLogin(props) {
  const {clientId, onSuccess, onFailure, buttonWidth = 212, buttonId = 'gsiLoginButton'} = props;

  const handleCallback = res => {
    if (!res || !res.credential) return onFailure();
    return onSuccess(res.credential);
  };

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: clientId,
      callback: handleCallback,
    });

    google.accounts.id.renderButton(document.getElementById(buttonId), {
      type: 'standard',
      theme: 'filled_black',
      size: 'large',
      shape: 'square',
      logo_alignment: 'left',
      width: buttonWidth,
      text: 'Đăng nhập thông qua Google',
    });
  }, []);

  return (
    <div>
      <div id={buttonId} />
    </div>
  );
}
