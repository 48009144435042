import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link, navigate} from 'gatsby';
import {Row, Col, Input, Badge, Menu, Divider, Popover} from 'antd';
import {Header} from 'antd/lib/layout/layout';
import {RightOutlined} from '@ant-design/icons';

import {get, put} from '~utils/api';
import {buildImageUrl, convertCategoryTreeData, generateTreeList} from '~utils/helper';
import logo from '~images/logo.png';
import {hotline, email} from '~constants/rentzy-info';
import {logout, relogin} from '~actions/user';

import Favorite from './icons/favorite';
import Mail from './icons/mail';
import Phone from './icons/phone';
import BriefCase from './icons/briefcase';
import OrderHistory from './icons/order-history';
import User from './icons/user';
import MenuIcon from './icons/menu';
import SearchIcon from './icons/search';
import CartPopover from './cart-popover';
import LoginModal from '~containers/authenticate/login-modal';
import Bell from './icons/bell';
import NotificationPopover from './notification-popover';

const {SubMenu} = Menu;

const HeaderComponent = ({siteTitle, searchKey, isLoginPage, user, logoutUser, reloginUser}) => {
  const [searchValue, setSearchValue] = useState(searchKey);
  const [selectedCate, setSelectedCate] = useState(0);
  const [category, setCategory] = useState({});
  const [brands, setBrands] = useState({});
  const [memberShip, setMemberShip] = useState({});
  const [isLogged, setLogged] = useState(false);
  const [visiblePopover, setPopover] = useState(false);
  const [visibleLogin, setVisibleLogin] = useState(false);
  const [notifiCounter, setNotifiCounter] = useState(0);

  useEffect(() => {
    const getMenu = async () => {
      const res = await get('/v1/menu');
      if (!res) return;

      setCategory(res.items.find(x => x.text === 'Danh mục'));
      setBrands(res.items.find(x => x.text === 'Thương hiệu'));
      setMemberShip(res.items.find(x => x.text === 'Gói thành viên'));
    };

    const checkNotification = async () => {
      const res = await get('/v1/notification/check');
      if (res) {
        setNotifiCounter(res.count);
      }
    };

    Promise.all([getMenu(), checkNotification()]);
  }, []);

  useEffect(() => {
    if (user && user.username) {
      setLogged(true);
    } else {
      setLogged(false);
      if (window.location.pathname !== '/login/') {
        reloginUser();
      }
    }
  }, [user]);

  const handleChange = e => {
    setSearchValue(e.target.value);
  };

  const onSearch = value => {
    if (!value) return;
    const urlParams = new URLSearchParams();
    urlParams.append('keyword', value);
    navigate(`/search?${urlParams.toString()}`);
  };

  const handleMouseOver = item => {
    if (item.children) setSelectedCate(item.id);
  };

  const handleLogout = () => {
    logoutUser();
    setLogged(false);
    setPopover(false);
  };

  const handlePopoverChange = visible => {
    setPopover(visible);
  };

  const handleDeleteAllNotification = async () => {
    setNotifiCounter(0);
    await put(`/v1/notification`, {
      ids: [],
      isReadAll: true,
    });
  };

  const getCateMenu = categories => {
    const rootParent = categories.filter(x => !x.parentId);
    const remainData = categories.filter(x => !!x.parentId);
    const treeData = generateTreeList(rootParent, remainData);
    const fullCategory = convertCategoryTreeData(categories, treeData);

    return (
      <div className='cate-menu'>
        <ul style={{listStyleType: 'none', marginTop: '10px'}}>
          <li>
            <Link className='category' to='/all-categories'>
              Tất Cả
            </Link>
          </li>
          {fullCategory.map(x => (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <li key={x.id} onMouseOver={() => handleMouseOver(x)} onMouseLeave={() => setSelectedCate(0)}>
              <Link className='category' to={x.link}>
                <div className='c-title'>{x.title}</div>
                <div>
                  {x.children && (
                    <span>
                      <RightOutlined />
                    </span>
                  )}
                </div>
              </Link>
              {x.id === selectedCate && (
                <div className='sub-cate'>
                  {x.children && (
                    <ul>
                      {x.children.map(y => (
                        <li key={`sub-${y.id}`} className='sub-item'>
                          <Link className='sub-c-title' to={y.link}>
                            {y.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <Header className='layout-header'>
      <Row justify='center'>
        <Col lg={24} xl={22}>
          <Row justify='end'>
            <Col>
              <ul className='header-1'>
                <li className='h1-item'>
                  <Mail size='18' />
                  <span className='title'>{email}</span>
                </li>
                <li className='h1-item'>
                  <Phone size='18' />
                  <span className='title'>Hotline: {hotline}</span>
                </li>
                <li className='h1-item'>
                  <BriefCase size='18' />
                  <span className='title'>Trở thành đối tác</span>
                </li>
                <li className='h1-item'>
                  <OrderHistory size='18' />
                  <span className='title'>Theo Dõi Đơn Hàng</span>
                </li>
                <li className='h1-item'>
                  <User size='18' />
                  {isLogged && (
                    <Popover
                      content={
                        <div>
                          <Link className='account-header-item' to='/account/profile'>
                            <span style={{cursor: 'pointer'}}>Tài khoản</span>
                          </Link>
                          <div className='account-header-item' onClick={handleLogout}>
                            <span style={{cursor: 'pointer'}}>Đăng xuất</span>
                          </div>
                        </div>
                      }
                      title=''
                      trigger='click'
                      visible={visiblePopover}
                      onVisibleChange={handlePopoverChange}
                    >
                      <span className='title'>{user?.username}</span>
                    </Popover>
                  )}
                  {!isLogged && <span onClick={() => setVisibleLogin(true)}>Đăng Ký | Đăng Nhập</span>}
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider style={{color: '#E0E0E0'}} />
      {!isLoginPage && (
        <Row justify='center'>
          <Col lg={24} xl={22}>
            <ul className='header-2'>
              <li className='left'>
                <ul className='header-2-menu-left'>
                  <li style={{minWidth: '124px'}}>
                    <Link to='/'>
                      <img src={logo} alt='Rentzy logo' className='rentzy-logo' />
                    </Link>
                  </li>
                  <li>
                    {category.text && brands.text && memberShip.text && (
                      <Menu className='menu' mode='horizontal' style={{background: 'transparent', minWidth: '500px'}}>
                        <SubMenu
                          className='category-menu'
                          icon={<MenuIcon size='20' fill='#000000' />}
                          title={category.text}
                        >
                          {getCateMenu(category.categoryItems)}
                        </SubMenu>
                        <SubMenu title={brands.text}>
                          <div className='brand-menu'>
                            {brands.brandItems.map(x => (
                              <Link key={x.name} className='b-item' to={x.link}>
                                <div className='b-icon'>
                                  <img src={buildImageUrl(x.logo)} alt={x.name} />
                                </div>
                                <div className='b-title'>{x.name}</div>
                              </Link>
                            ))}
                          </div>
                        </SubMenu>
                        <Menu.Item>
                          <Link to={memberShip.link}>{memberShip.text}</Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to='https://blog.rentzy.vn/'>Blog</Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to={`//thanhly.${process.env.GATSBY_BASE_DOMAIN}`}>Thanh lý</Link>
                        </Menu.Item>
                      </Menu>
                    )}
                  </li>
                  <li style={{width: '100%', maxWidth: '600px'}}>
                    <Input.Search
                      className='searching'
                      placeholder='Nhập nội dung...'
                      allowClear
                      size='large'
                      value={searchValue}
                      onChange={handleChange}
                      onSearch={onSearch}
                      enterButton={
                        <div style={{display: 'inline-flex', alignItems: 'center'}}>
                          <span style={{marginRight: '10px', textTransform: 'capitalize', fontWeight: 500}}>
                            Tìm kiếm
                          </span>
                          <SearchIcon size='18' fill='#ffffff' />
                        </div>
                      }
                    />
                  </li>
                </ul>
              </li>
              <li className='right'>
                <Favorite size='22' />
                {isLogged && (
                  <Popover
                    className='badge-notify'
                    content={<NotificationPopover count={notifiCounter} onDeleteAll={handleDeleteAllNotification} />}
                    id='popover-notify'
                    title={<b>Thông báo</b>}
                    trigger='click'
                    placement='bottomRight'
                  >
                    <Badge size='default' count={notifiCounter}>
                      <Bell fill='#000000' />
                    </Badge>
                  </Popover>
                )}
                <CartPopover />
              </li>
            </ul>
          </Col>
        </Row>
      )}
      <LoginModal isVisible={visibleLogin} onClose={() => setVisibleLogin(false)} />
    </Header>
  );
};

HeaderComponent.propTypes = {
  siteTitle: PropTypes.string,
};

HeaderComponent.defaultProps = {
  siteTitle: ``,
};

export default connect(
  state => ({
    user: state.user.user,
  }),
  dispatch => ({
    logoutUser: () => dispatch(logout()),
    reloginUser: () => dispatch(relogin()),
  })
)(HeaderComponent);
