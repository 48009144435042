import React from 'react';

const OrderHistory = ({fill = '#828282', size = '24'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 16 22'
    width={size}
    height={size}
    fill={fill}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.364 12.1214C11.2876 13.045 11.4831 14.4211 10.9504 15.5362L12.4853 17.0711C12.8758 17.4616 12.8758 18.0948 12.4853 18.4853C12.0948 18.8758 11.4616 18.8758 11.0711 18.4853L9.53615 16.9504C8.42102 17.4831 7.04496 17.2876 6.12132 16.364C4.94975 15.1924 4.94975 13.2929 6.12132 12.1214C7.29289 10.9498 9.19239 10.9498 10.364 12.1214ZM8.94975 14.9498C9.34027 14.5593 9.34027 13.9261 8.94975 13.5356C8.55922 13.145 7.92606 13.145 7.53553 13.5356C7.14501 13.9261 7.14501 14.5593 7.53553 14.9498C7.92606 15.3403 8.55922 15.3403 8.94975 14.9498Z'
    />
    <path d='M4 4H12V6H4V4Z' />
    <path d='M12 8H4V10H12V8Z' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V19C16 20.6569 14.6569 22 13 22H3C1.34315 22 0 20.6569 0 19V3ZM3 2H13C13.5523 2 14 2.44772 14 3V19C14 19.5523 13.5523 20 13 20H3C2.44772 20 2 19.5523 2 19V3C2 2.44772 2.44771 2 3 2Z'
    />
  </svg>
);

export default OrderHistory;
