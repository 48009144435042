import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, Button, Divider, message} from 'antd';
import {UserProviders} from '~utils/enum';
import {CloseOutlined, LoadingOutlined} from '@ant-design/icons';
import {FacebookProvider, LoginButton} from 'react-facebook';
import {connect} from 'react-redux';
import {login as loginConsumer} from '~actions/user';
import {Link} from 'gatsby';
import Facebook from '~components/icons/facebook';
import notification, {NotifyTypes} from '~utils/modals/notification';
import GoogleLogin from '~components/google-login';

const LoginModal = ({isVisible, onClose, loginConsumerDispatch, user}) => {
  const [loading, setLoading] = useState(true);

  const handleLogin = async data => {
    try {
      setLoading(true);
      const res = await loginConsumerDispatch(data);
      if (!res) notification(NotifyTypes.ERROR, {message: 'Tên đăng nhập hoặc mật khẩu sai, vui lòng thử lại sau!'});
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async values => {
    if (values) {
      const data = {provider: UserProviders.Default, ...values};
      await handleLogin(data);
    }
  };

  const onFinishFailed = errorInfo => {
    // eslint-disable-next-line no-console
    console.log('Failed:', errorInfo);
  };

  const responseFacebook = async response => {
    const tokenValue = response?.tokenDetail?.accessToken;
    if (tokenValue) {
      const data = {provider: UserProviders.Facebook, token: tokenValue};
      await handleLogin(data);
    }
  };

  const responseGoogle = async token => {
    if (!token) {
      message.error('Đã có lỗi xảy ra vui lòng thử lại!', 5);
    }

    const data = {provider: UserProviders.Google, token};
    await handleLogin(data);
  };

  const handleError = error => {
    // eslint-disable-next-line no-console
    console.log(error);
  };

  useEffect(() => {
    setLoading(false);
    if (user && user.token) {
      onClose();
    }
  }, [user]);

  return (
    <Modal visible={isVisible} footer={null} width={502}>
      <div className='authen-modal-header'>
        <span className='title'>Đăng nhập</span>
        <span className='close-icon' onClick={() => onClose()}>
          <CloseOutlined style={{fontSize: 15}} />
        </span>
      </div>
      <div className='authen-modal-content'>
        <div className='authen-modal-form'>
          <Form
            name='loginform'
            className='login-form'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout='vertical'
            size='large'
            requiredMark='optional'
          >
            <Form.Item
              className='input-username'
              label='Username'
              name='username'
              rules={[{required: true, message: 'Vui lòng điền vào mục này!'}]}
            >
              <Input placeholder='Tên tài khoản/ email/ số điện thoại' />
            </Form.Item>

            <Form.Item label='Password' name='password' rules={[{required: true, message: 'Vui lòng nhập mật khẩu!'}]}>
              <Input.Password placeholder='Nhập mật khẩu' />
            </Form.Item>

            <Form.Item>
              <Button className='btn-submit' type='primary' htmlType='submit' disabled={loading}>
                {loading && (
                  <span className='loading-icon'>
                    <LoadingOutlined />
                  </span>
                )}
                <span>Đăng Nhập</span>
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className='forgot-password'>
          <Link to='/forgot-password'>Quên Mật Khẩu?</Link>
        </div>
        <div className='login-other-app'>
          <FacebookProvider appId={process.env.GATSBY_FACEBOOK_APP_ID || 'app_id'}>
            <LoginButton scope='email' className='facebook-btn' onCompleted={responseFacebook} onError={handleError}>
              <span className='facebook-icon'>
                <Facebook size='20' />
              </span>
              <span>Đăng nhập thông qua Facebook</span>
            </LoginButton>
          </FacebookProvider>
          <GoogleLogin
            clientId={process.env.GATSBY_GOOGLE_CLIENT_ID}
            onSuccess={responseGoogle}
            onFailure={handleError}
            buttonWidth={344}
            buttonId='googleLoginBtnOnModal'
          />
          <Divider />
        </div>
        <div className='register'>
          <button type='button'>
            <Link to='/register'>Đăng kí</Link>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  state => ({
    user: state.user.user,
  }),
  dispatch => ({
    loginConsumerDispatch: values => dispatch(loginConsumer(values)),
  })
)(LoginModal);
