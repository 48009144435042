import {Link} from 'gatsby';
import React, {useState, useEffect} from 'react';
import {get} from '~utils/api';
import {formatDate, generateUrlParameters, objectEmpty} from '~utils/helper';
import AvatarNotification from '~images/avatar-notification.png';
import {Empty} from 'antd';

import * as styles from './styles.module.css';

const NotificationPopover = ({count, onDeleteAll}) => {
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    const fetchNotificationList = async () => {
      const urlParams = generateUrlParameters({
        'pagination.limit': 10,
        'pagination.page': 1,
        'sort.prop_name': 'CreatedDate',
        'sort.direction': 'Desc',
      });

      const res = await get(`/v1/notification/list?${urlParams}`);
      if (!objectEmpty(res)) {
        setNotificationList(res.items);
      } else {
        setNotificationList([]);
      }
    };

    fetchNotificationList();
  }, [count]);

  return (
    <div className={styles.notContent}>
      {notificationList.length > 0 && count > 0 && (
        <div className={styles.dismissAll} onClick={onDeleteAll}>
          Bỏ qua tất cả
        </div>
      )}
      <div>
        <div>
          {notificationList.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Chưa có thông báo!' />
          )}
          {notificationList.map((item, index) => (
            <Link key={item.id} to='/account/notification'>
              <div className={styles.item}>
                <div>
                  <img src={AvatarNotification} alt='' srcSet='' width={34} height={34} />
                </div>
                <div>
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.content}>{item.content}</div>
                  <div className={styles.bottom}>
                    <div className={styles.date}>({formatDate(new Date(item.createdDate), 'LL')})</div>
                    {item.wasRead && <div className={styles.read}>Đã đọc</div>}
                  </div>
                </div>
              </div>
              {index < notificationList.length - 1 && <div className={styles.borderLine} />}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotificationPopover;
