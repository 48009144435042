import React from 'react';

import {buildImageUrl, formatDate, getTextColor, toVND} from '~utils/helper';
import {generatePrice} from '~utils/com-helper';

import './styles.scss';

const CartItem = ({item}) => {
  const onClick = () => {
    // navigate(`/cart/${parseNameToUrl(item.name)}.prod.${item.id}`);
  };

  return (
    <div className='cart-item' onClick={onClick}>
      <div
        className='cart-image'
        style={{
          backgroundImage: `url(${buildImageUrl(item.productImage, '60x90')})`,
        }}
      />
      <div className='cart-content'>
        <div className='cart-title'>{item.productName}</div>
        {item.dateFrom && item.dateTo && (
          <div className='date-range'>
            Ngày thuê: <span className='rental-date'>{formatDate(item.dateFrom, 'DD/MM/YYYY')}</span>đến
            <span className='rental-date'>{formatDate(item.dateTo, 'DD/MM/YYYY')}</span>
          </div>
        )}
        <div className='attribute'>
          {item.attributes.map((x, i) => (
            <div key={i} className='cart-attr-item'>
              {x.name}:{' '}
              {x.type === 'VALUE_TYPE_COLOR' ? (
                <span
                  className='margin-left attr-color'
                  style={{backgroundColor: x.value, color: getTextColor(x.value)}}
                />
              ) : (
                <span className='margin-left attr-item'>{x.value}</span>
              )}
            </div>
          ))}
        </div>
        <div className='quantity'>
          <div className='count'>
            Số lượng: <span className='count-number'>{item.quantities}</span>
          </div>
        </div>
        <div className='item-price'>Giá bán: {generatePrice(item.originalPrice, item.salePrice)}</div>
        <div className='item-price item-rental'>
          Giá thuê: {generatePrice(item.originalRentalPrice, item.rentalPrice)}
        </div>
        <div className='item-price item-rental'>
          Giá membership:{' '}
          <span className='item-new-price'>
            {item.membershipRentalPrice ? toVND(item.membershipRentalPrice) : 'N/A'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
