import React from 'react';

const SearchIcon = ({fill = '#white', size = '24'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 19 20'
    width={size}
    height={size}
    fill={fill}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.319 13.4326C16.7628 10.2941 16.542 5.75344 13.6569 2.86826C10.5327 -0.255933 5.46734 -0.255933 2.34315 2.86826C-0.781049 5.99246 -0.781049 11.0578 2.34315 14.182C5.22833 17.0672 9.76899 17.2879 12.9075 14.8442C12.921 14.8594 12.9351 14.8744 12.9497 14.8891L17.1924 19.1317C17.5829 19.5222 18.2161 19.5222 18.6066 19.1317C18.9971 18.7412 18.9971 18.108 18.6066 17.7175L14.364 13.4749C14.3493 13.4602 14.3343 13.4461 14.319 13.4326ZM12.2426 4.28248C14.5858 6.62562 14.5858 10.4246 12.2426 12.7678C9.8995 15.1109 6.1005 15.1109 3.75736 12.7678C1.41421 10.4246 1.41421 6.62562 3.75736 4.28248C6.1005 1.93933 9.8995 1.93933 12.2426 4.28248Z'
      fill='white'
    />
  </svg>
);

export default SearchIcon;
