import React from 'react';

const Phone = ({fill = '#828282', size = '24'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 20 20'
    width={size}
    height={size}
    fill={fill}
  >
    <path d='M20 10C20 8.68677 19.7413 7.38647 19.2388 6.1731C18.7362 4.95996 17.9997 3.85742 17.0711 2.92896C16.1425 2.00024 15.0401 1.26367 13.8268 0.76123C12.6136 0.258545 11.3132 0 10 0V2C11.0506 2 12.0909 2.20703 13.0615 2.60889C14.0321 3.01099 14.914 3.60034 15.6569 4.34326C16.3997 5.08594 16.989 5.96802 17.391 6.93848C17.7931 7.90918 18 8.94946 18 10H20Z' />
    <path d='M0 8V3C0 2.44775 0.447723 2 1 2H6C6.55228 2 7 2.44775 7 3V7C7 7.55225 6.55228 8 6 8H4C4 12.4182 7.58173 16 12 16V14C12 13.4478 12.4477 13 13 13H17C17.5523 13 18 13.4478 18 14V19C18 19.5522 17.5523 20 17 20H12C5.37259 20 0 14.6274 0 8Z' />
    <path d='M15.5433 7.70386C15.8448 8.43188 16 9.21216 16 10H14.2C14.2 9.44849 14.0914 8.90234 13.8803 8.39282C13.6692 7.88306 13.3599 7.42017 12.9698 7.03027C12.5798 6.64014 12.1169 6.33081 11.6073 6.11963C11.0977 5.90869 10.5515 5.80005 10 5.80005V4C10.7879 4 11.5681 4.15527 12.2961 4.45679C13.024 4.7583 13.6855 5.2002 14.2426 5.75732C14.7998 6.31445 15.2418 6.97583 15.5433 7.70386Z' />
  </svg>
);

export default Phone;
