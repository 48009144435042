import React from 'react';

const Bell = ({fill = '#828282'}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='20' fill='none' viewBox='0 0 16 22'>
      <path
        fill={fill}
        fillRule='evenodd'
        d='M10 2v.29c2.892.86 5 3.539 5 6.71v7h1v2H0v-2h1V9a7.003 7.003 0 015-6.71V2a2 2 0 114 0zM3 16h10V9A5 5 0 003 9v7zm7 4v-1H6v1a2 2 0 104 0z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export default Bell;
