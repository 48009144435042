import React from 'react';

const Facebook = ({fill = '#000000', size = '24'}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} fill={fill} viewBox='0 0 12 20'>
      <path
        fill='#fff'
        d='M10.917.004L8.324 0C5.41 0 3.527 1.932 3.527 4.922v2.27H.92a.408.408 0 00-.408.407v3.288c0 .226.182.408.408.408h2.607v8.297c0 .226.183.408.408.408h3.402a.408.408 0 00.408-.408v-8.297h3.05a.408.408 0 00.407-.408l.001-3.288a.409.409 0 00-.408-.408h-3.05V5.268c0-.925.22-1.394 1.425-1.394l1.747-.001a.408.408 0 00.408-.408V.412a.408.408 0 00-.408-.408z'
      />
    </svg>
  );
};

export default Facebook;
