const WEB_HOOK_URL = process.env.GATSBY_SLACK_WEBHOOK_URL;
const WEB_HOOK_CHANNEL = process.env.GATSBY_SLACK_WEBHOOK_CHANNEL;

const notifyWebhook = async request => {
  const payload = {
    channel: WEB_HOOK_CHANNEL,
    username: `Webhook - Desktop error`,
    text: request,
    icon_emoji: ':warning:',
  };

  await fetch(WEB_HOOK_URL, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export default notifyWebhook;
