/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import {useStaticQuery, graphql} from 'gatsby';
import {Layout, BackTop} from 'antd';
import {UpOutlined} from '@ant-design/icons';

import Header from './header';
import Footer from './footer';
import ErrorBoundary from '~utils/error-boundary';

import './layout.scss';

const {Content} = Layout;

const LayoutComponent = ({searchKey, children, isLoginPage}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ErrorBoundary>
      <Layout style={{background: '#ffffff'}}>
        <Header
          siteTitle={data.site.siteMetadata?.title || `Website Rentzy`}
          searchKey={searchKey}
          isLoginPage={isLoginPage}
        />
        <Content className='layout-content'>
          <div className='page-content'>{children}</div>
        </Content>
        <Footer />
        <BackTop className='back-top'>
          <div className='back-top-icon'>
            <UpOutlined />
          </div>
        </BackTop>
      </Layout>
    </ErrorBoundary>
  );
};

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutComponent;
