import React from 'react';

const GoogleStoreCircle = ({fill = '#000000', size = '24'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 512 512'
    enableBackground='0 0 512 512'
    width={size}
    height={size}
    fill={fill}
  >
    <circle style={{fill}} cx='256' cy='256' r='256' />
    <path
      style={{fill: '#ffffff'}}
      d='M118.106,190.708L118.106,190.708c-13.037,0-23.606,10.569-23.606,23.606v99.606
	c0,13.037,10.569,23.606,23.606,23.606l0,0c13.037,0,23.606-10.569,23.606-23.606v-99.606
	C141.712,201.276,131.143,190.708,118.106,190.708z'
    />
    <path
      style={{fill: '#ffffff'}}
      d='M393.894,190.708L393.894,190.708c-13.037,0-23.606,10.569-23.606,23.606v99.606
	c0,13.037,10.569,23.606,23.606,23.606l0,0c13.037,0,23.606-10.569,23.606-23.606v-99.606
	C417.5,201.276,406.931,190.708,393.894,190.708z'
    />
    <path
      style={{fill: '#ffffff'}}
      d='M314.055,105.4l17.568-29.125l-14.791-8.921l-18.028,29.889c-12.943-5.614-27.22-8.732-42.228-8.732
	l0,0c-15.008,0-29.285,3.117-42.228,8.732l-18.028-29.89l-14.791,8.921l17.568,29.126c-28.266,18.227-47.328,49.499-48.665,85.308
	h212.287C361.383,154.899,342.321,123.627,314.055,105.4z M208.5,150.405c-5.962,0-10.795-4.833-10.795-10.795
	c0-5.962,4.833-10.795,10.795-10.795s10.795,4.833,10.795,10.795C219.295,145.571,214.462,150.405,208.5,150.405z M303.5,150.405
	c-5.962,0-10.795-4.833-10.795-10.795c0-5.962,4.833-10.795,10.795-10.795s10.795,4.833,10.795,10.795
	C314.295,145.571,309.462,150.405,303.5,150.405z'
    />
    <path
      style={{fill: '#ffffff'}}
      d='M149.773,372.359h42.03v52.682c0,13.037,10.569,23.606,23.606,23.606l0,0
	c13.037,0,23.606-10.569,23.606-23.606v-52.682h32.818v52.682c0,13.037,10.569,23.606,23.606,23.606l0,0
	c13.037,0,23.606-10.569,23.606-23.606v-52.682h43.182V206.541H149.773V372.359z'
    />
  </svg>
);

export default GoogleStoreCircle;
